<template>
  <div class="container-top">
    <div class="pc container">
      <div class="h5 main padding-top-44">Solution API 견적 신청</div>
      <div class="subtitle3 main padding-top-52">서비스 정보</div>
      <div class="lp-divider-sub5 padding-top-24"></div>
      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`"
          :class="`margin-top-${form.component_name === 'lp-text' ? '16' : '32'}`">
        <div class="flex margin-top-16">
          <div class="inquiry-reg-title subtitle5">
            {{ form.field!=='checkedVal'? form.label : '' }} <!--{{ form.validate.required? '*':'' }}-->
          </div>
          <div class="inquiry-reg-content">
            <component :is="form.component_name"
                       :ref="form.field"
                       v-bind.sync="form"></component>
          </div>
        </div>

        <div v-if="form.field==='content'" class="lp-divider-gray2 margin-top-32"></div>
        <div v-if="form.field==='content'" class="subtitle3 main padding-top-52">신청자 정보</div>
        <div v-if="form.field==='content'" class="lp-divider-sub5 padding-top-24 margin-bottom-32"></div>
      </div>
      <div class="margin-top-80 flex-center">
        <button class="button is-primary" style="width:242px;height:48px" @click="saveData('pc')">견적 신청하기</button>
      </div>
    </div>
    <div class="mobile container">
      <div class="h7 main padding-top-24">Solution API 견적 신청</div>
      <div class="h8 sub margin-top-16">서비스 정보</div>
      <div class="lp-divider-gray2 margin-top-8 margin-bottom-8"></div>

      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
        <div class="margin-top-12">
          <div class="subtitle7 sub">{{ form.field!=='checkedVal'? form.label : '' }} <!--{{ form.validate.required? '*':'' }}--></div>
          <component :is="form.component_name"
                     class="margin-top-8"
                     v-bind.sync="form"></component>
        </div>
        <div v-if="form.field==='content'" class="h8 main padding-top-48">신청자 정보</div>
        <div v-if="form.field==='content'" class="lp-divider-gray2 margin-top-8 margin-bottom-8"></div>
      </div>

      <button class="button is-primary margin-top-40 body2-bold"
              style="width:100%;height:48px;"
              @click="saveData('mobile')"
              v-if="!$route.query.id">신청하기
      </button>
    </div>
    <div class="sweet-modal-pc">
      <sweet-modal ref="successModal" overlay-theme="dark" :width="modalWidth" :blocking="true" @close="$refs.successModal.close()"
                   :enable-mobile-fullscreen="false"
                   :hide-close-button="true">
        <div class="modal-container" style="padding: 10px;">
          <div class="subtitle4 main margin-top-12">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-64">
            <button class="button body2-bold sub2"
                    style="width:50%;height:52px"
                    @click="$router.replace('/home')">홈으로</button>
            <button class="button is-primary body2-bold margin-left-16"
                    style="width:50%;height:52px"
                    @click="$router.replace(`/solution_api_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>

    <div class="success-modal-mobile">
      <sweet-modal ref="successModalMobile" overlay-theme="dark" :width="modalWidth" :blocking="true" @close="$refs.successModal.close()"
                   :enable-mobile-fullscreen="false"
                   :hide-close-button="true">
        <div class="modal-container" style="padding: 10px;">
          <div class="body4 main margin-top-36">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-52">
            <button class="button body5-medium sub2"
                    style="width:50%;height:36px"
                    @click="$router.replace('/home')">홈으로 가기</button>
            <button class="button is-primary body5-medium margin-left-16"
                    style="width:50%;height:36px"
                    @click="$router.replace(`/solution_api_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
  import FormMixin from "../../mixins/FormMixin";
  import CheckboxGroup from "../module/CheckboxGroup";
  import LpTextArea from "../component/LpTextArea";
  import LpRadio from "../module/LpRadio";
  import LpText from "../component/LpText";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import { SweetModal } from 'sweet-modal-vue';

  export default {
    name: "SolutionApiReg",
    components: {LpCheckBoxArray, LpText, LpRadio, LpTextArea, CheckboxGroup, SweetModal},
    mixins: [
      FormMixin
    ],
    created() {
     /* this.$nextTick(()=>{
       this.$refs.successModal.open();
     })*/
      if(window.innerWidth<500) {
        this.modalWidth = 'none';
        this.checkFontSize = '12px';
      }
      if(this.isLogin) {
        this.formData.forEach(item=>{
          ['name','phone','email'].forEach(key=>{
            if(item.field === key) {
              item.value = this.user[key];
            }
          })
        });
      } else {
        let item = {
          label: '',
          items: [
            {
              label: "위 정보로 회원가입하여 다음부터 'Solution API 신청 내역'에서 확인할 수 있게 합니다",
              labelStyle: {
                fontSize: this.checkFontSize,
                lineHeight: '15px',
                color: '#555'
              }
            },
            {
              label: '(필수)', labelStyle: {
                fontSize: this.checkFontSize,
                lineHeight: '15px',
                color: '#FF6600'
              }
            }
          ],
          field: 'check_signup',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: false
          },
        }
        this.formData.push(item);
      }
    },
    data() {
      return {
        modalWidth: 560,
        checkFontSize: '15px',
        formData: [
          {
            col: 12,
            label: '주문전환 방식 (복수선택 가능)',
            component_name: 'checkbox-group',
            field: 'packages',
            value: ['주문배송 플랫폼'],
            errorMsg: '',
            option: [
              {
                label: '주문배송 플랫폼',
                value: '주문배송 플랫폼'
              },
              {
                label: '시간단위 예약 플랫폼',
                value: '시간단위 예약 플랫폼'
              },
              {
                label: '기간단위 예약 플랫폼',
                value: '기간단위 예약 플랫폼'
              },
              {
                label: '소개 플랫폼 (전화주문)',
                value: '소개 플랫폼 (전화주문)'
              },
            ],
            validate: {
              valid_required: true,
              required: true
            }
          },
          {
            block: true,
            label: '제공범위',
            field: 'support',
            value: '관리자사이트 API + 운영솔루션',
            component_name: 'lp-radio',
            errorMsg: '',
            data: [
              {
                label: '관리자사이트 API + 운영솔루션',
                value: '관리자사이트 API + 운영솔루션'
              },
              {
                label: '관리자사이트 API + 운영솔루션 + 프론트엔드 소스코드',
                value: '관리자사이트 API + 운영솔루션 + 프론트엔드 소스코드'
              },
            ],
            validate: {
              valid_required: true,
              required: true
            }
          },
          {
            label: '요청사항',
            placeholder: '요청 사항을 적어주세요.',
            maxLength: 1000,
            field: 'content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 1000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '성함(혹은 회사명)',
            placeholder: '성함을 입력해주세요.',
            maxLength: 30,
            field: 'name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '연락처',
            placeholder: '연락처를 입력해주세요.',
            maxLength: 30,
            field: 'phone',
            type: 'phone',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
            maxLength: 30,
            field: 'email',
            type: 'email',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '개인정보 수집 및 이용안내 동의',
            items: [
              {
                label: '개인정보 수집 및 이용안내 동의',
                labelStyle: {
                  fontSize: this.checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)',
                labelStyle: {
                  fontSize: this.checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'privacy',
                labelStyle: {
                  color: '#828282',
                  fontSize: this.checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true
            },
          }
        ],
        inquiry_id: 0
      }
    },
    watch: {
      enableCheckSignup(n) {
        if(n) {
          let items = [
            {
              label: '비밀번호',
              placeholder: '비밀번호를 입력해주세요.',
              maxLength: 30,
              field: 'password',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            },
            {
              label: '비밀번호 확인',
              placeholder: '비밀번호 확인을 입력해주세요.',
              maxLength: 30,
              field: 'password_new',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            }
          ]
          this.formData = this.formData.concat(items);
        } else {
          let pass = this.formData.findIndex(item=>{ return item.field === 'password'});
          if(pass > -1) {
            this.formData.remove(pass);
            let password_new = this.formData.findIndex(item=>{ return item.field === 'password_new'})
            this.formData.remove(password_new);
          }
        }
      }
    },
    computed: {
      // 회원가입 체크 여부
      enableCheckSignup() {
        let item = this.findItem(this.formData, 'field', 'check_signup');
        return item && item.value;
      }
    },
    methods: {
      saveData(device) {
        let result = this.getParamData(this.formData);
        if(result.enable) {
          if(this.isLogin) {
            this.regInquiry(result, device);
          } else {
            if (!this.enableCheckSignup) {
              this.toast('필수 사항에 체크하신 후 진행해주세요.')
            } else if (result.params.password !== result.params.password_new) {
              this.toast('비밀번호 확인값이 일치하지 않습니다.')
            } else {
              let params = {
                username: result.params.email,
                name: result.params.name,
                password: result.params.password,
                phone: result.params.phone,
                email: result.params.email,
              };
              this.$axios.post('auth/v1/user/signup', params).then(res => {
                if (res.status === 200) {
                  this.$store.commit('setUser', res.data.user);
                  this.setAuthHeader();
                  this.regInquiry(result, device);
                }
              }).catch(error => {
                if (error.response) {
                  let res = error.response;
                  this.toast(res.data.message);
                }
              });
            }
          }
        }
      },
    regInquiry(result, device) {
        let params = {
          category: 1,
          name: result.params.name,
          email: result.params.email,
          phone: result.params.phone,
          content: result.params.content,
          api_request: this.cloneItem(result.params),
          channel: '런치팩 / API',
          service_name: 'API 신청'
        };
        if (this.isLogin) {
          params.user_id = this.user.user_id;
        }
        this.$axios.post('public/launchpack/v1/inquiry', params).then(res => {
          if (res.status === 200) {
            this.inquiry_id = res.data;
            if(device==='pc') this.$refs.successModal.open();
            else this.$refs.successModalMobile.open();
          }
        }).catch(err => {
          console.log(err)
          if (err.response.data.message) {
            this.toast(err.response.data.message);
          } else if (err.response.data.detail) {
            this.toast(err.response.data.detail);
          } else {
            this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
          }
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .pc .inquiry-reg-title
    width 30%

  .pc .inquiry-reg-content
    width 70%

  .modal-container
    padding 20px
</style>
<style lang="stylus">
  .success-modal-mobile .sweet-modal.is-visible
    height 184px

  .success-modal-mobile .sweet-modal.is-alert .sweet-content
    padding-top 0
    padding-bottom 0
</style>
